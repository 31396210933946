import React from "react"
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Text,
  Heading,
} from "@chakra-ui/react"

import { StaticImage } from "gatsby-plugin-image"

import MethodologyContent from "./methodology-content"

const styles = {
  root: {
    width: "100%",
  },
  container: {
    my: "60px",
    mx: { base: "16px", md: "60px", lg: "60px" },
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    fontWeight: 700,
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    color: "#2D3748",
  },
  "sub-heading": {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21px",
    color: "#2D3748",
  },
  hero: {
    mt: "20px",
    width: "130px",
  },
}

const MethodologyDrawer = ({ onClose, isOpen }) => {
  return (
    <Box sx={styles.root}>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p="0px">
            <Box sx={styles.container}>
              <Heading sx={styles.heading}>Research Methodology</Heading>

              <Box mt="32px">
                <Text sx={styles["sub-heading"]}>
                  Healthcare Research All-Stars
                </Text>
                <Box sx={styles.hero}>
                  <StaticImage
                    src="../../../images/allstar/Healthcare Research All-Stars 2024 - Logo.png"
                    alt="Healthcare Research All-Stars Logo"
                    placeholder="blurred"
                  />
                </Box>
              </Box>
              <Box mt="32px">
                <MethodologyContent isDrawerContent />
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default MethodologyDrawer
