import React from "react"
import { Box, Text, Flex, OrderedList, ListItem } from "@chakra-ui/react"

const MethodologyContent = ({ isDrawerContent = false }) => {
  const styles = {
    root: {
      color: "#2D3748",
    },
    "sub-heading": {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0em",
    },
    "list-heading": {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0em",
    },
    step: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: "#595C5F",
      pr: "16px",
      minWidth: "70px",
    },
    text: {
      fontSize: isDrawerContent ? "14px" : "16px",
      fontWeight: 400,
      lineHeight: isDrawerContent ? "20px" : "24px",
      letterSpacing: "0em",
    },
    item: {
      flexDirection: { base: "column", md: "row", lg: "row" },
    },
  }

  return (
    <Box sx={styles.root}>
      <Box>
        <Text sx={styles["sub-heading"]}>Objective</Text>
        <Text sx={styles.text} mt="4px">
          To honor surgeons and hospitals for their research productivity during
          2021-2022.
        </Text>
      </Box>

      <Flex mt="32px" sx={styles.item}>
        <Text sx={styles.step}>Step 1.</Text>
        <Box>
          <Text sx={styles["list-heading"]}>Identify Surgeons</Text>
          <Text sx={styles.text} mt="4px">
            Surgeons are identified based on their specialty information in the
            2023 National Downloadable NPI file and National Plan and Provider
            Enumeration System (NPPES) file published by the Centers for
            Medicare and Medicaid Services. All surgeons are included if they
            have one of the following primary specialties: cardiology and
            cardiothoracic surgery, gastroenterology, general surgery,
            neurosurgery, orthopedic surgery, otolaryngology (ENT), spine
            surgery, surgical oncology, urology, and vascular surgery.
          </Text>
        </Box>
      </Flex>

      <Flex mt="32px" sx={styles.item}>
        <Text sx={styles.step}>Step 2.</Text>
        <Box>
          <Text sx={styles["list-heading"]}>Obtain Procedural Volumes</Text>
          <Text sx={styles.text} mt="4px">
            Medicare procedural volume comes from the 100% 2021-2022 Medicare
            fee-for-service inpatient and outpatient claims data.
          </Text>
          <Text sx={styles.text} mt="12px">
            Surgeons who performed 8 or fewer procedures for Medicare patients
            in total in 2021-2022 are excluded from further analysis. In addition,
            surgeons who graduated from a medical school within 6 years are
            excluded since they are likely still in a training program.
          </Text>
          <Text sx={styles.text} mt="12px">
            Surgical specialties are determined based on Medicare procedural
            volumes. A surgeon is attributed to a specialty if more than
            50% of their total procedures are in that specialty. For
            orthopedics, subspecialties are similarly classified – including hip
            & knee, foot & ankle, shoulder & elbow, hand & wrist, and
            generalists.
          </Text>
          <Text sx={styles.text} mt="12px">
            Hospitals are identified based on the same Medicare claims data. To be
            included in the Research All-Stars a hospital has to have at least
            one eligible surgeon during 2021-2022.
            To be eligible for the overall hospital-level Research All-Stars across 10
            surgical specialties, each hospital is required to have at least 30
            surgeons and 5 surgical specialties represented.
          </Text>
        </Box>
      </Flex>

      <Flex mt="32px" sx={styles.item}>
        <Text sx={styles.step}>Step 3.</Text>
        <Box>
          <Text sx={styles["list-heading"]}>
            Attribute Surgeons to Hospitals
          </Text>
          <Text sx={styles.text} mt="4px">
            Using a plurality rule, a surgeon is attributed to a hospital if the
            hospital:
          </Text>
          <OrderedList>
            <ListItem sx={styles.text} mt="12px">
              has the largest number of procedures performed by the surgeon for
              Medicare patients; and
            </ListItem>
            <ListItem sx={styles.text}>
              accounts for ≥30% of the surgeon’s total Medicare volume; and
            </ListItem>
            <ListItem sx={styles.text}>
              has the most recent procedure if there is a tie
            </ListItem>
          </OrderedList>
          <Text sx={styles.text} mt="12px">
            Surgeons who perform Medicare procedures in multiple hospitals
            without any of the hospitals meeting the 30% threshold are included
            in surgeon-level Research All-Stars but excluded from hospital-level
            Research All-Stars.
          </Text>
        </Box>
      </Flex>

      <Flex mt="32px" sx={styles.item}>
        <Text sx={styles.step}>Step 4.</Text>
        <Box>
          <Text sx={styles["list-heading"]}>
            Compile Peer-Reviewed Publications by Surgeons
          </Text>
          <Text sx={styles.text} mt="4px">
            From the PubMed database, peer-reviewed articles published between
            2021 and 2022 are imported for analysis. An article is attributed to
            a surgeon if the author's name and affiliation match the surgeon's
            information. We also make sure the publication topic is related to
            the surgeon's specialty for a higher matching accuracy.
          </Text>
        </Box>
      </Flex>

      <Flex mt="32px" sx={styles.item}>
        <Text sx={styles.step}>Step 5.</Text>
        <Box>
          <Text sx={styles["list-heading"]}>Calculate Publication Weights</Text>
          <Text sx={styles.text} mt="4px">
            All publications come from PubMed.
            Publications are weighted using two factors: Journal
            Impact Factor and author position. Letters to editors are excluded
            from the calculation since they are not comparable to other research
            articles.
          </Text>
          <OrderedList mt="12px">
            <ListItem sx={styles.text}>Journal Impact Factor</ListItem>
            <ListItem sx={styles.text}>
              <Text>Author position</Text>
              <OrderedList>
                <ListItem listStyleType="lower-alpha">
                  Single author: 100%
                </ListItem>
                <ListItem listStyleType="lower-alpha">
                  Two authors: 1st author = 60%; last author = 40%
                </ListItem>
                <ListItem listStyleType="lower-alpha">
                  ≥3 authors: 1st author = 45%, last author = 30% each; other
                  authors = 25% / (total # of authors - 2)
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem sx={styles.text}>
              Author-specific weighted individual article score = Journal Impact
              Factor x Author Position Weight
            </ListItem>
          </OrderedList>
        </Box>
      </Flex>

      <Flex mt="32px" sx={styles.item}>
        <Text sx={styles.step}>Step 6.</Text>
        <Box>
          <Text sx={styles["list-heading"]}>
            Create a Weighted Publication Score
          </Text>
          <Text sx={styles.text} mt="4px">
            A weighted publication score is calculated for each surgeon and each
            hospital.
          </Text>
          <OrderedList mt="12px">
            <ListItem sx={styles.text}>
              Surgeon weighted publication score = Sum(author-specific weighted
              individual article score)
            </ListItem>

            <ListItem sx={styles.text}>
              <Text>For each surgical specialty:</Text>
              <OrderedList>
                <ListItem listStyleType="lower-alpha">
                  Hospital weighted average publication score = Sum(Surgeon
                  weighted publication score)/(Number of attributed surgeons)
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem sx={styles.text}>
              <Text>For all 10 surgical specialties:</Text>
              <OrderedList>
                <ListItem listStyleType="lower-alpha">
                  Normalize surgeon weighted publication scores for each
                  surgical specialty to a 0-100 scale. This is to ensure the
                  comparability across surgical specialties given that journal
                  impact factors vary largely across specialties.
                </ListItem>
                <ListItem listStyleType="lower-alpha">
                  Normalized hospital weighted average publication score =
                  Sum(Normalized surgeon weighted publication score across all
                  10 specialties)/(Number of attributed surgeons in all 10
                  specialties)
                </ListItem>
              </OrderedList>
            </ListItem>
          </OrderedList>
        </Box>
      </Flex>

      <Box mt="56px" mb="29px">
        <Text sx={styles["sub-heading"]}>Additional Notes</Text>
        <OrderedList>
          <ListItem sx={styles.text}>
            Not all surgeons serve Medicare patients and those who opt out of
            Medicare are not included because no Medicare surgical volume
            information is available.
          </ListItem>
          <ListItem sx={styles.text}>
            Research All-Stars are based on hospital-based procedures. If a
            surgeon performs in ambulatory surgical centers only, that surgeon
            is not included.
          </ListItem>
          <ListItem sx={styles.text}>
            There are research centers where many non-surgeon researchers
            publish peer-reviewed articles. Since Research All-Stars focus on
            surgeons, non-surgeon researchers are not included. If no surgeons
            co-author an article, that article is not captured in our analysis.
          </ListItem>
          <ListItem sx={styles.text}>
            In the PubMed database, occasionally, there are articles in which no
            affiliation information is available for authors, and these
            publications are not included.
          </ListItem>
        </OrderedList>
      </Box>
    </Box>
  )
}

export default MethodologyContent
