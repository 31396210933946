import React from "react"

import Layout from "../components/allstar/layout"
import SectionHero from "../components/allstar/resource/faq/section-hero"
import SectionBody from "../components/allstar/resource/faq/section-body"

const ResourceFAQPage = props => {
  return (
    <Layout
      location={props.location}
      title={"Frequently Asked Questions (FAQ)"}
      isResourceSite
    >
      <SectionHero />
      <SectionBody />
    </Layout>
  )
}

export default ResourceFAQPage
